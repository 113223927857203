@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
/* You can add global styles to this file, and also import other style files */
@import '../../../styles/main.scss';
@import '~mapbox-gl/dist/mapbox-gl.css';

.cdk-overlay-dark-backdrop {
  background: var(--color--background-dark-overlay);
}
.mapboxgl-popup-content {
  background: #8c648c;
}

.mapboxgl-popup-tip {
  border-top-color: #8c648c !important;
}
p:empty {
  display: inline-block;
}
